import { ReactElement } from "react";

// Images
import bbeating from "../images/babyeatingService.png";
import babyHug from "../images/babyhugService.png";
import carryIndiv from "../images/carryIndiv.png";
import carry from "../images/carry.png";
import parentsRelay from "../images/parentsRelay.png";
import diver from "../images/diver.png";
import rebornBath from "../images/bainRenaissance.png";
import babyHand from "../images/babyhandService.png";
import friendService from "../images/friendService.png";
import resserageBassin from "../images/resserageBassin.jpg";

interface Service {
  title: string;
  description: ReactElement;
  goal?: ReactElement;
  time?: string;
  price?: number;
  image?: any;
}
export const servicesData: Service[] = [
  {
    title: "Accompagnement allaitement",
    description: (
      <p>
        L’allaitement est un choix <strong>PERSONNEL</strong>.<br />
        Une évidence pour certaines femmes, une découverte pour d’autres.
        <br />
        <br />
        Je me déplace à votre domicile, afin de vous accompagner, de vous
        informer sur votre futur ou début d’allaitement. En matière
        d’allaitement, la clé de la réussite est l’accompagnement ! Plus on est
        informée et accompagnée de manière bienveillante, mieux on sait où on va
        !
      </p>
    ),
    goal: (
      <p>
        Accueillir bébé en toute tranquillité grâce à différentes mises en
        situation du quotidien.
      </p>
    ),
    time: "1H",
    price: 50,
    image: bbeating,
  },
  {
    title: "Bain Enveloppé",
    description: (
      <p>
        Le bain enveloppé (ou bain contenant) est un soin qui tente de
        reproduire les sensations éprouvées par le bébé dans le ventre de sa
        mère. Ce soin est un moyen pour l'enfant d'interagir avec ses parents.
      </p>
    ),
    goal: (
      <p>
        Transmettre des techniques d’apaisement et renforcer le lien avec bébé.
      </p>
    ),
    time: "2H",
    price: 70,
    image: babyHug,
  },
  {
    title: "Le bain renaissance®",
    description: (
      <p>
        Bien plus qu'un bain, ce soin, a pour but de recréer les sensations que
        bébé connait par cœur. Lové dans les bras de son parent, bébé raconte
        son histoire. Une rencontre qui rime avec douceur et émotion.
      </p>
    ),
    goal: <p>Accompagner bébé vers un lâcher prise et un apaisement.</p>,
    time: "2H",
    price: 90,
    image: rebornBath,
  },
  {
    title: "Accompagnement soins bébé",
    description: (
      <p>
        S’occuper d’un bébé est une évidence pour certains et pour d’autres, une
        découverte. Quoi de mieux que de pouvoir se « former » au
        maternage/paternage, avant l’arrivée de votre bébé ?
        <br />
        <br />
        Durant cet accompagnement, je vous aiderai à vous familiariser avec les
        soins quotidiens d’un bébé, et à être serein lors de votre rencontre. Je
        serai à votre écoute et répondrai à toutes les questions que vous
        pourrez vous poser.
      </p>
    ),
    goal: (
      <p>
        Accueillir bébé en toute tranquillité grâce à différentes mises en
        situation du quotidien.
      </p>
    ),
    time: "1H",
    price: 50,
    image: babyHand,
  },
  {
    title: "Atelier portage individuel ",
    description: (
      <p>
        Les bébés sont portés depuis la nuit des temps dans le monde entier. Ils
        sont portés par besoin, par nécessité ou pour faciliter la vie
        quotidienne.
        <br />
        <br />
        L’enfant, une fois hors du ventre de sa mère continue sa croissance. Et
        pour bien se faire, il a besoin de chaleur, de proximité et de sécurité.
        Le bébé humain a besoin d’être porté.
        <br />
        <br />
        <i>« Pour bien se détacher, l’enfant doit pouvoir bien s’attacher » </i>
        <br />
        Bernard GOLSE, pédopsychiatre.
      </p>
    ),
    time: "2H",
    price: 70,
    image: carryIndiv,
    goal: (
      <p>
        Vous familiariser avec le système de portage qui vous convient le mieux.
      </p>
    ),
  },
  {
    title: "Atelier portage ciblé ",
    description: (
      <p>
        Vous êtes déjà équipé d’une écharpe, super! L’atelier sera ciblé, et
        sera l’occasion de vous familiariser avec votre écharpe.
        <br />
        <br /> L’atelier peut s’effectuer avant comme après la naissance. Elle
        s’adresse autant aux mamans, qu’aux papas.
      </p>
    ),
    time: "1h30",
    price: 40,
    image: carry,
    goal: (
      <p>
        Vous familiariser avec le système de portage qui vous convient le mieux.
      </p>
    ),
  },
  {
    title: "Massage bébé",
    description: (
      <p>
        Les bienfaits du toucher sont nombreux. Masser son bébé permet de
        développer la relation avec lui, de contribuer à son développement, à
        son éveil moteur, intellectuel et émotionnel, et de lui assurer un
        bien-être physique et émotionnel.
        <br />
        <br />
        Durant cet atelier, je vous accompagne et vous transmet différentes
        techniques de massage qui accompagneront votre quotidien.
        <br />
        <br />
        Cette prestation se divise en plusieurs session d’environ 45 minutes
        maximum (en fonction des acquisitions, besoins de bébé)
      </p>
    ),
    time: "4 séances de 45 minutes",
    price: 150,
    image: diver,
    goal: (
      <p>
        Vous accompagner et vous transmettre différentes techniques de massage
        qui accompagneront votre quotidien.{" "}
      </p>
    ),
  },
  {
    title: "Relais parental",
    description: (
      <p>
        Il arrive parfois, que les débuts avec son bébé ne soient pas évidents.
        <br />
        <br />
        Il faut savoir que chaque bébé a son histoire son expérience et donc ses
        propres émotions.
        <br />
        <br /> Il peut être difficile d’assurer de manière efficace ses journées
        lorsque le manque de sommeil se fait ressentir ou lors d’un baby blues,
        ou d’une dépression du post-partum.
        <br />
        <br /> Cette prestation, consiste à vous relayer la nuit ou la journée.
        Je viens à votre domicile et vous relaie auprès de votre/vos
        nouveau-nés, afin de vous permettre de vous « requinquer ».
        <br />
        <br /> Durant ce temps, j’accompagne votre enfant au sommeil, dans les
        soins. Je vous accompagne si besoin dans l’allaitement, et autres soins.
        <br />
        <br />
        Mon rôle est de soutenir les jeunes parents, de leur permettre de
        souffler, sans culpabiliser et surtout de vous donner confiance dans
        leur nouveau quotidien et leur nouveau rôle.
      </p>
    ),
    image: parentsRelay,
    goal: (
      <p>
        Vous permettre de souffler, sans culpabiliser et surtout de vous donner
        confiance dans votre nouveau quotidien et votre nouveau rôle.
      </p>
    ),
  },
  /*
  {
    title: 'Cercle de parents',
    description: (
      <p>
        Proposés de manière ponctuel, les groupes de parole permettent
        d’échanger et de partager autour de la maternité et/ou de la paternité.
        Chaque histoire autour de la naissance est différente. La maternité est
        souvent décrite comme un moment extraordinaire.
        <br />
        <br /> Mais cette période est un véritable tsunami émotionnel. Fait
        d’inquiétudes, d’angoisses, de peurs voire de conflits au sein du couple
        ou de la famille.
        <br />
        <br /> Sans oublier que pendant la grossesse et à la naissance, toute
        l’attention est, le plus souvent, portée sur la maman et le bébé mais
        qu’en est-il papa ou du co- parent ?<br /> C’est une période
        d’adaptation. Un nouveau rôle dans lequel il peut manquer de confiance,
        être mal à l’aise et cette adaptation nécessite parfois du temps.
        <br />
        Trouver sa place peut ne pas être évident d’emblée, contrairement à la
        femme qui porte l’enfant, le co-parent devient concrètement
        papa/co-parent à la naissance.
        <br /> On le sait, le père ou le co-parent est figure d’attachement au
        même titre que la mère, auprès de son enfant. Une figure d’attachement
        dont l’enfant a besoin pour se sentir bien et en sécurité.
        <br /> Les cercles de parole, sont l’occasion d’échanger sur vos
        questionnements, vos expériences ou tout simplement d’écouter les «
        aventures » d’autres parents. Un moment où le parent peut se sentir
        moins seul dans son nouveau rôle.
      </p>
    ),
    image: parents,
  },
*/
  {
    title: "Ateliers divers",
    description: (
      <p>
        Je vous propose également des ateliers d’une heure, sur différents
        thèmes, les dangers domestiques, les maux de bébé, l’alimentation…
      </p>
    ),
    image: friendService,
    goal: <p> Échanger, partager sur différents thèmes autour de bébé.</p>,
  },
  {
    title: "Resserrage du bassin",
    description: (
      <p>
        Le serrage du bassin est une technique ancestrale pour aider à contenir
        le bassin. Particulièrement adapté au post-natal, il vise à soulager les
        ligaments mis à rude épreuve au cours de la grossesse et pendant
        l'accouchement, le serrage du bassin peut toutefois être proposé à tout
        moment.
        <br />
        <br /> Pour la femme qui vient d'accoucher, c'est le moyen de clore
        cette période de grossesse, de revenir dans son corps de femme pour
        envisager une nouvelle étape de sa vie.
      </p>
    ),
    price: 60,
    time: "4 séances de 15 minutes",
    image: resserageBassin,
    goal: (
      <p>
        Soulager les tensions du bassin, liées à la grossesse et l’accouchement.
      </p>
    ),
  },
];
