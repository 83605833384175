import React, { ReactElement } from "react";
import { Box, Flex, Heading, Icon, Stack, Text } from "@chakra-ui/react";
import { StarIcon } from "@heroicons/react/solid";
import { AdvicesData } from "../utils/AdvicesData";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Avatar } from "../images/svg/Avatar";
// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";

export const AdvicesSlider = () => {
  const displayStars = (score: number) => {
    let arrStars: ReactElement[] = [];
    for (let i = 0; i < score; i++) {
      arrStars.push(
        <StarIcon color={"#fdd663"} width={"2rem"} height={"2rem"} />
      );
    }
    return arrStars;
  };
  return (
    <Box w={"95%"} mx={"auto"}>
      <Heading size="md" mx="auto" width="fit-content">
        Les avis des autres parents
      </Heading>

      {/*  Card componant  */}

      <Flex gap={"1rem"} h={"14rem"}>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          className="mySwiper"
          breakpoints={{
            700: {
              slidesPerView: 2,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
        >
          {AdvicesData.map((advice) => (
            <SwiperSlide>
              <Box
                rounded={"2xl"}
                p={"1.25rem"}
                boxShadow={"rgba(0, 0, 0, 0.1) 0px 10px 30px"}
                mt={"1.5rem"}
                bg={"white"}
                h={"11rem"}
                cursor={"grab"}
                _active={{ cursor: "grabbing" }}
              >
                <Box>
                  <Text
                    align={"justify"}
                    overflow={"hidden"}
                    display={"-webkit-box"}
                    minH={"72px"}
                    sx={{
                      lineClamp: 3,
                      "-webkit-line-clamp": "3",
                      "-webkit-box-orient": "vertical",
                    }}
                  >
                    {advice.description}
                  </Text>
                  <Flex gap={"1rem"} mt={"1rem"}>
                    <Icon as={Avatar} w={7} h={7} />
                    <Box>
                      <Text>{advice.author}</Text>
                      <Stack direction={"row"}>
                        {displayStars(advice.score)}
                      </Stack>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Flex>
    </Box>
  );
};
