import { ReactElement } from "react";

interface Advice {
  author: string;
  description: ReactElement | string;
  image?: any;
  score: number;
}
export const AdvicesData: Advice[] = [
  {
    author: "Helene N'zoutani\n",
    description:
      "Dominique est un rayon de soleil, elle est passionnée, attentionnée et ses conseils sont précieux !",
    score: 5,
  },
  {
    author: "Cynthia Flamant",
    description:
      "Dominique est une personne adorable, a l’écoute et très empathique. Elle partage sa passion avec douceur et vous accompagne sans jugement dans votre projet d’allaitement. Notre échange m'a donné beaucoup plus confiance en moi pour tenter l'allaitement avec mon fils.",
    score: 5,
  },
  {
    author: "Karima A",
    description:
      "Merci Dominique de m’avoir aidé à prendre soin de mon bébé et de m’avoir guidé dans l’apprentissage des gestes et techniques durant la séance de massage, j’ai noté une grande différence dans les jours qui ont suivis. Pas toujours évident quand on est une jeune maman, elle m’a vraiment aidé à prendre confiance ! Bienveillante et douce, Dominique est vraiment la bonne fée des bébés, je recommande les yeux fermés !",
    score: 5,
  },
  {
    author: "Eva Scrive",
    description:
      "Chaque jeune parent devrait avoir la chance de croiser Dominique sur son chemin ! Elle m'a soutenu et guidé dans les premiers mois de vie de mes enfants, ses conseils sont précieux. Merci Dominique pour ton écoute, ta douceur et ta bienveillance",
    score: 5,
  },
  {
    author: "Arnaud Goguelat",
    description:
      "Dominique est une vraie pro mais également très humaine et bienveillante dans son accompagnement. Je recommande",
    score: 5,
  },
  {
    author: "Léa Duchateau",
    description:
      "Le sourire de Dominique a ce don de vous réconforter dès le premier instant.\n" +
      "Maman formidable et professionnelle impliquée, elle fera tout pour répondre à vos besoins.",
    score: 5,
  },
];
