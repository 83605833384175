import React from "react";
import { ArrowRightIcon } from "@heroicons/react/outline";
import { Introduction } from "../components/Introduction";
import { Box, Icon, Image, Link, Skeleton, Text } from "@chakra-ui/react";
import HomeBanner from "../images/HomeBanner.png";
import "swiper/css";
export const Home = () => {
  return (
    <>
      {/*1st part*/}
      {/*  Normal Screen size */}
      <Box minH="fit-content">
        <Image
          src={HomeBanner}
          w={"full"}
          srcSet={HomeBanner}
          maxH={"30rem"}
          objectFit="cover"
          fallback={<Skeleton minH={{ base: "10rem", sm: "30rem" }} />}
        />
      </Box>
      {/* End Normal Screen size */}
      {/*  end 1st part*/}
      <Box justifyContent="end" display="flex" p="3">
        <Link
          _hover={{ textDecoration: "underline" }}
          display="flex"
          href="/prestations"
        >
          <Text>Voir les services</Text>
          <Icon as={ArrowRightIcon} alignSelf="center" h={6} w={6} />
        </Link>{" "}
      </Box>
      <hr />
      <Introduction />
      {/*  2nd part */}
    </>
  );
};
