import React from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { servicesData } from "../utils/ServicesData";
import Branch from "../images/branch.png";

export const Services = () => {
  return (
    <>
      <Box>
          <Heading textAlign={'center'} padding={'3rem'} fontSize={{base : "4rem", md : "7.5rem"}} fontFamily={"Allura"}>
              Prestations
          </Heading>
      </Box>
      <Box w={"85%"} mx={"auto"} justifyContent={"center"}>
        <Text textAlign={"center"} fontSize={"1.85rem"} fontFamily={"Allura"}>
          "Je suis là, tout simplement pour vous épauler, vous écouter, et vous
          accompagner dans votre nouveau rôle"
        </Text>
      </Box>
      <Divider />
      <Grid
        w={"85%"}
        mx={"auto"}
        px={"1.25rem"}
        justifyContent={"center"}
        mt={"3rem"}
        templateColumns={{
          base: "repeat(0, minmax(0, 1fr))",
          md: "repeat(2, minmax(0, 1fr))",
          lg: "repeat(3, minmax(0, 1fr))",
        }}
        flexWrap={"wrap"}
        gap={"0.5rem"}
      >
        {servicesData.map((item: any) => (
          <GridItem
            p={"1rem"}
            w={{ base: "auto" }}
            borderRadius={"xl"}
            bgColor={"rgba(231, 187, 174, 0.28)"}
            minH={"18rem"}
            key={item.title}
            colSpan={1}
            my={"2.5rem"}
            boxShadow={"rgba(0, 0, 0, 0.1) 0px 10px 10px"}
          >
            <Box mb={"0.5rem"}>
              <Text textAlign={"end"}>
                {" "}
                {item.price ? `${item.price} €` : "Sur devis"}{" "}
              </Text>
              <Image src={item.image} w={"auto"} mx="auto" mt="-5rem" />
            </Box>
            <Stack spacing="3">
              <Heading size="md" p={"0.5rem"} textAlign={"center"}>
                {item.title}
                <Text textAlign={"center"} fontSize={"sm"} minH={"1rem"}>
                  {item.time}
                </Text>
              </Heading>
              <Image src={Branch} w={"5rem"} mx="auto" mt="-0.5rem" />
              <Text
                maxH={"15rem"}
                minH={"15rem"}
                overflowY={"auto"}
                pr={"1rem"}
                textAlign={"justify"}
              >
                {item.description}
              </Text>
              <Box minH="5rem">
                {item.goal && (
                  <>
                    <Text fontWeight={"medium"} textDecoration={"underline"}>
                      Objectif :{" "}
                    </Text>{" "}
                    <Text>{item.goal}</Text>
                  </>
                )}
              </Box>
            </Stack>
            <Flex w={"min"} mx={"auto"} mb={"-2rem"} mt={"1rem"}>
              <Link to="/contact">
                <Button variant="solid" colorScheme="blue" borderRadius={"xl"}>
                  Me contacter
                </Button>
              </Link>
            </Flex>
          </GridItem>
        ))}
      </Grid>
    </>
  );
};
