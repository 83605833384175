import { Disclosure } from "@headlessui/react";
import React from "react";
import { XIcon, MenuIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { Image } from "@chakra-ui/react";
import logo from "../images/logo.jpg";
interface Navigation {
  index: number;
  name: string;
  href: string;
  current: boolean;
}

const headerItem: Navigation[] = [
  { index: 1, name: "Accueil", href: "/", current: false },
  { index: 3, name: "Prestations", href: "/prestations", current: false },
  { index: 4, name: "Contact", href: "/contact", current: false },
];

function Navbar() {
  return (
    <Disclosure as="nav">
      {({ open, close }) => (
        <div className="bg-[#e7bbae] border-[1px] border-b-black">
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16 max-h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile Screen */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              {/* Phone Link */}
              <div className="sm:hidden ml-40" style={{ margin: "auto" }}>
                <Link to="/">
                  <Image width="4rem" src={logo} rounded="full" />
                </Link>
              </div>
              {/* END Phone Link */}

              {/* Normal Screen menu */}
              <div className="xs:hidden sm:flex m-auto">
                <Link to="/">
                  <Image maxW={"4rem"} src={logo} rounded={"full"} />
                </Link>
              </div>
              <div className="xs:hidden flex-1 sm:flex items-center justify-center">
                <ul className="flex flex-1 justify-evenly">
                  {headerItem.map((item) => (
                    <Link to={item.href}>
                      <li
                        key={item.index.toString()}
                        className={
                          "text-gray-900 hover:bg-[#d0a89b] hover:text-white transition duration-300 px-3 py-2 rounded-md text-sm font-medium hover:cursor-pointer text-center"
                        }
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>
              {/* END Normal Screen menu */}
            </div>
          </div>

          {/* End mobile Screen */}
          {/* Phone menu */}
          <Disclosure.Panel>
            <div className="flex-col flex items-center justify-center">
              <ul className="flex flex-col justify-evenly">
                {headerItem.map((item) => (
                  <li
                    key={item.index.toString()}
                    className={
                      "text-gray-900 hover:bg-[#d0a89b] hover:text-white transition duration-300 px-3 py-2 rounded-md text-sm font-medium hover:cursor-pointer text-center"
                    }
                    aria-current={item.current ? "page" : undefined}
                    onClick={() => close()}
                  >
                    <Link to={item.href}>{item.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </Disclosure.Panel>
          {/* Phone menu */}
        </div>
      )}
    </Disclosure>
  );
}

export default Navbar;
