import React from "react";
import instagram from "../images/instagram.jpg";
import { Link } from "react-router-dom";
import { PhoneIcon } from "@heroicons/react/solid";
import { Icon, Text } from "@chakra-ui/react";

const Footer = () => {
  const instagramURL = "https://www.instagram.com/on.nait.bien.ensemble/";
  const whatApp =
    "https://api.whatsapp.com/send/?phone=33783886498&text&type=phone_number&app_absent=0";
  return (
    <div className="inset-x-0 bottom-0 p-3 mt-12 flex flex-col mx-auto border-t-4 items-center gap-3">
      <Link to={instagramURL} target="_blank" className="w-fit mx-auto">
        <div className="flex rounded-md mt-1.5 justify-center p-3 h-2 w-auto items-center whitespace-nowrap border-2 border-red-400 border-dashed hover:bg-red-200 transition">
          <img src={instagram} alt="leaves" className="w-5 h-5" />
          <span className="text-red-600 ml-1">Suivez moi sur instagram !</span>
        </div>
      </Link>
      <div className={"flex items-center"}>
        <Icon as={PhoneIcon} />
        {" : "}
        <Link to={whatApp} target="_blank">
          <Text
            ml={"2"}
            textDecoration={"underline"}
            _hover={{ fontWeight: "medium" }}
          >
            +33 7 83 88 64 98
          </Text>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
