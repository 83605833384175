import React, { LegacyRef, useRef } from "react";
import emailjs from "@emailjs/browser";
import {
  Box,
  FormLabel,
  Grid,
  Heading,
  Image,
  Input,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import corner from "../images/leavesConer.png";
import { AdvicesSlider } from "../components/AdvicesSlider";

export const Contact = () => {
  const form: LegacyRef<any> = useRef();
  const toast = useToast();
  const toastIdRef = React.useRef();
  const sendEmail = (e: any) => {
    e.preventDefault();
    emailjs
      .sendForm(
        // @ts-ignore
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        () => {
          toastIdRef.current = toast({
            description: "Message envoyé !",
          }) as any;
        },
        () => {
          toastIdRef.current = toast({
            description: "Erreur lors de l'envoie du message",
          }) as any;
        }
      );
  };

  return (
    <Box>
      <Grid
        w={{ md: "75%" }}
        justifyContent={"center"}
        mx={{ md: "auto" }}
        p={{ base: "2rem", md: "6rem" }}
        h={"fit-content"}
      >
        <Image
          src={corner}
          h={"10rem"}
          display={{ base: "none", sm: "flex" }}
          rotate={"180turn"}
          justifySelf={"flex-end"}
          margin={"-5rem -5rem"}
        />
        <Box>
          <Text display={{ base: "grid" }} my={"1.5"} mx={"auto"}>
            <Heading size="lg" mx="auto">
              {" "}
              N'hésitez pas à me contacter
            </Heading>
          </Text>
          <Text>
            {" "}
            Vous souhaitez avoir plus d'informations sur les prestations ?
          </Text>
          <Text>
            Je vous apporterai toutes les précisions nécessaires dans les
            meilleurs délais
          </Text>
          <form ref={form} onSubmit={sendEmail}>
            <FormLabel>Nom</FormLabel>
            <Input placeholder="Nom" name={"user_name"} isRequired={true} />
            <FormLabel>Mail</FormLabel>

            <Input type="email" placeholder="Mail" name={"user_mail"} />
            <FormLabel>Votre message</FormLabel>

            <Textarea name="message" placeholder="Votre message" />

            <Input
              mt={"1.5"}
              bgColor={"#de8b6d"}
              type={"submit"}
              cursor={"pointer"}
            />
          </form>
        </Box>
        <Image
          src={corner}
          h={"10rem"}
          display={{ base: "none", sm: "flex" }}
          rotate={"180deg"}
          transform={"auto"}
          margin={"-3rem -5rem"}
          zIndex={"-1"}
        />
      </Grid>
      <AdvicesSlider />
    </Box>
  );
};
