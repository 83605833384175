import React from "react";
import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import dominique from "../images/dominique.jpg";
import badgeAmbassadriceRemovebg from "../images/badgeAmbassadriceRemovebg.png";
import Branch from "../images/branch.png";
export const Introduction = () => {
  return (
    <Box>
      <Flex
        gap={"1rem"}
        ml={"3mm"}
        direction={{ base: "column", sm: "column", md: "row" }}
        my={"1rem"}
        w={{ xl: "4xl" }}
        mx={{ base: "3rem", xl: "auto" }}
      >
        <Image
          src={dominique}
          boxSize="15rem"
          rounded={"full"}
          className={"xs:mx-auto  md:left-0 sm:mx-0"}
          mx={{ base: "auto", md: "0" }}
        />
        <Stack
          mt={"1rem"}
          textAlign={{ base: "center", md: "justify" }}
          w={{ base: "auto" }}
        >
          {" "}
          <Text>Moi, c'est Dominique, </Text>
          <Text
            as={"span"}
            fontWeight={"thin"}
            fontSize={"sm"}
            lineHeight={"short"}
          >
            Accompagnante périnatale
          </Text>
          <Text>
            {" "}
            {`J'accompagne les futurs et jeunes parents
            dans l'aventure de la maternité.`}
            <br /> <br />
            {`Mère de 3 enfants, je suis consciente des enjeux que représentent la parentalité.`}
            <br />
            {`Auxiliaire de puériculture diplômée d’état, j’ai débuté ma carrière en maternité, 
             puis j'ai poursuivi mon chemin en pouponnière, puis en centre maternel (foyer mère enfant)
             où j’ai accompagné parents et enfants, puis en hôpital spécialisé dans le lien parent/enfant.`}
          </Text>
        </Stack>
      </Flex>
      <Flex>
        <Image src={Branch} w={"7rem"} mx="auto" mt="-0.5rem" />
      </Flex>
      <Flex
        gap={"1rem"}
        direction={{ base: "column", sm: "column", md: "row-reverse" }}
        my={"1rem"}
        w={{ xl: "4xl" }}
        mx={{ base: "3rem", xl: "auto" }}
      >
        <Image
          src={badgeAmbassadriceRemovebg}
          boxSize="17rem"
          right={"1"}
          objectFit={"contain"}
          mx={{ base: "auto", md: "0" }}
        />
        <Stack
          mt={"1rem"}
          textAlign={{ base: "center", md: "justify" }}
          w={{ base: "auto" }}
        >
          <Text>
            {`Ces différentes expériences durant lesquelles j'ai beaucoup appris sur l'enfant et sa famille,
             m'ont menée à un constat : "le parent n'est pas suffisamment informé".`}
          </Text>
          <Text>
            {`J'ai donc suivi plusieurs formations à l'école du Bien Naître dont je suis ambassadrice,
             mais également auprès d’autres organismes spécialisés en périnatalité.`}
          </Text>
          <Text>
            {`Aujourd’hui je propose un accompagnement périnatal, pour le « bien-naître » de la famille.
           L’arrivée d’un bébé n’est pas seulement la naissance de bébé,
            mais la naissance d’un père, d’une mère, d’une famille.`}
          </Text>
          <Text>
            {`Je vous accompagne avec bienveillance, vous écoute sans jugement, vous soutiens avec douceur,
            vous transmets avec passion, et vous soulage avec chaleur.`}
          </Text>
          <Text>{`Vous l’avez compris… On Naît Bien Ensemble.`}</Text>
        </Stack>
      </Flex>
    </Box>
  );
};
